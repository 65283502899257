<template>

  <template v-if="zoomSelection === 'small-view' && birdseyeAlwaysOpen">
    <highlight-result :class="[zoomSelection, 'card', getMarkupClasses(item)]" :element-data="elementData"
                      :allowHighlight="!isOpen" v-for="(item, index) in elementData.computed?.detailSection"
                      :key="elementKey + index">
      <small-view-result :icon-name="iconName" />
    </highlight-result>
  </template>

  <template v-else-if="zoomSelection === 'small-view'">
    <highlight-result :element-data="elementData" :allowHighlight="!isOpen">
      <small-view-result @click.stop="toggleFocusedView()" :icon-name="iconName" />
    </highlight-result>
  </template>


  <template v-else>
    <highlight-result :element-data="elementData" :allowHighlight="!isOpen"
                      :class="{ 'is-open': isOpen, 'is-scrollable': isScrollable }">
      <div class="card-body d-flex flex-column justify-content-center align-items-center">
        <div v-if="!alwaysOpen"
             class="result-container d-flex align-items-center w-100 justify-content-between"
             :href="'#' + elementKey"
             aria-expanded="false"
             aria-controls="elementKey">
          <div class="card-title-container">
            <template v-if="!!showImagesOnClose && !isOpen">
              <span>
                <img v-for="image in elementData.imagecontainer.image.slice(0, 4)" :key="image.desc"
                     class="highlighted-image " :class="getMarkupClasses(image)" :src="image.desc"
                     :alt="image.alt || ''">
              </span>
            </template>
            <template v-else>
              <span v-if="iconName"
                    class="icons"
                    :class="[iconName, { clickable: focusedViewSupported }]"
                    @click.stop="toggleFocusedView()">
              </span>
              <span class="card-title m-auto"
                    :class="{ ellipsis: isOpen }"
                    v-html="displayName">
              </span>
            </template>
          </div>
          <div>
            <i v-if="canSubresultsOpen && isFocused" @click.stop="toggleOpenAll()"
               class="bi bi-chevron-double-down rotate text-primary" :class="{ up: isAllOpen }" />
            <i v-if="!isFocused" @click.stop="toggleOpen()" class="bi bi-chevron-down rotate text-primary"
               :class="{ up: isOpen }" />
          </div>
        </div>

        <i v-if="alwaysOpen" class="flag icons" :class="iconName"></i>

        <div class="collapse w-100" :class="{ show: isOpen }" :id="elementKey">
          <div class="accordion " :class="{ condensed }" :id="elementKey + '_acrdn'">

            <slot :accordionKey="elementKey + '_acrdn'" />

          </div>
        </div>
      </div>
    </highlight-result>
  </template>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import HighlightResult from "@/components/card/HighlightResult.vue";
import SmallViewResult from "@/components/card/SmallViewResult.vue";

export default {
  name: "ContainerWrapper",
  emits: ["focusViewClick"],
  props: {
    elementKey: {
      type: String,
      required: true,
    },
    elementData: {
      type: Object,
      required: true,
    },
    displayName: String,
    iconName: String,
    alwaysOpen: Boolean,
    birdseyeAlwaysOpen: {
      type: Boolean,
      default: false,
    },
    condensed: Boolean,
    showImagesOnClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    // for now only PAA has subresults that can open. If another type eventually gets this, move toggling children logic here
    canSubresultsOpen: Boolean,
    isAnySubresultOpen: Boolean,
  },
  components: {
    HighlightResult,
    SmallViewResult,
  },

  computed: {
    ...mapGetters("timeline", {
      isResultOpen: "isResultOpen",
      isSubResultsOpen: "isSubResultsOpen",
      focusedElement: "focusedElement",
      paaOnlyViewFromLegacy: "paaOnlyViewFromLegacy",
    }),
    ...mapGetters("toolbar", { zoomSelection: "zoomSelection" }),
    ...mapGetters("routeParams", { view: "view" }),
    resultId() {
      return this.elementData.resulttype;
    },
    isOpen() {
      return this.alwaysOpen || this.isFocused || this.isResultOpen(this.resultId);
    },
    isFocused() {
      return this.focusedElement === this.resultId;
    },
    isScrollable() {
      const maxRows = this.condensed ? 8 : 4; // keep in sync with the timeline-container-scrolling-heights in sizes.scss
      return !this.isFocused && !this.isAnySubresultOpen && this.elementData.computed.detailSection.length > maxRows;
    },

    focusedViewSupported() {
      return this.view === "timeline" && !this.paaOnlyViewFromLegacy;
    },
    isAllOpen() {
      return this.isSubResultsOpen(this.resultId);
    },
  },
  methods: {
    ...mapActions("timeline", ["toggleResultOpen", "toggleSubResultsOpen", "setFocusedElement"]),
    ...mapActions("toolbar", ["setZoomSelection"]),

    toggleOpen() {
      if (this.isFocused) {
        this.toggleOpenAll();
      } else {
        this.toggleResultOpen(this.resultId);
      }
    },
    toggleOpenAll() {
      if (this.canSubresultsOpen) {
        this.toggleSubResultsOpen(this.resultId);
        if (!this.isOpen && this.isAllOpen) {
          this.toggleResultOpen(this.resultId);
        }
      }
    },
    toggleFocusedView() {
      if (!this.focusedViewSupported) {
        return;
      }

      this.setFocusedElement(this.isFocused ? "" : this.resultId);
      this.$emit('focusViewClick');
      if (this.isAllOpen && !this.isFocused) {
        this.toggleSubResultsOpen(this.resultId);
      }
      if (this.isFocused) {
        this.toggleOpenAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.is-open:not(.small-view) {
  border: solid 1px var(--timeline-result-separator-color);

  .result-container {
    background-color: var(--timeline-card-container-header-bg);
  }
}

*,
:slotted(*) {
  &.small-view {
    .card {
      width: var(--timeline-card-width-small);
      margin: var(--timeline-result-margin-small);
    }
  }
}

.regular-view,
.medium-view {
  border-radius: var(--border-radius-timeline-container);

  .card-title-container {
    display: flex;
    align-items: center;

    .icons {
      margin-top: -2px;
    }

    span {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }

  .result-container {
    padding-left: 0.3em;
    transition: var(--transition-zoom-selection), var(--transition-open-result);
  }

  .collapse {
    transition: all 0.5s ease;

    &:not(.show) {
      display: inherit;
      height: 0px;
      overflow: hidden;
      opacity: 0;
    }
  }

  .rotate {
    line-height: 0px;
    display: inline-block;
    @include hover-clickable-effect;

    &.up {
      transform: rotate(-180deg);
      transition: transform 0.2s ease-in-out;
      @include hover-clickable-effect(rotate(-180deg));
    }
  }

  *,
  :slotted(*) {
    .card-title {
      color: var(--fb-blue-70);
      font-weight: 500;
      margin: 0;
    }

    .ellipsis {
      @include ellipsis;
    }

    .card-body {
      padding: 0;
      border-radius: 0.25rem;
    }
  }

  .icons {
    margin-right: 0.5rem;
  }

  .flag {
    position: absolute;
    top: 2px;
    right: -5px;
  }

}

.regular-view {
  img {
        max-width: 19%;
      }
}

.medium-view {
  img {
    max-width: 23%;
  }
}

@mixin zoomViewSizes($type) {

  .#{$type}-view {
    &.is-open .result-container {
      height: calc(var(--timeline-font-size-#{$type}) + var(--timeline-result-width-padding));
    }

    .card-title-container span :deep(img) {
      border: solid var(--highlight-image-outline-width-#{$type});
      border-color: white;
    }

    .card-title.ellipsis {
      max-width: var(--result-text-width-container-title-icon-#{$type});
    }

    .result-container {
      height: var(--timeline-result-height-#{$type});

      .rotate {
        font-size: var(--timeline-accordion-toggle-font-size-#{$type});
        margin-right: calc(var(--timeline-accordion-toggle-right-offset-#{$type}) + 1px);
      }
    }

    .adjust-for-kp {
      width: calc(var(--result-text-width-kp-adjust-#{$type}) + var(--timeline-result-width-padding));
      transition: var(--transition-zoom-selection);

      :slotted(*) {
        .ellipsis {
          max-width: var(--result-text-width-kp-adjust-#{$type});
        }

        .markup-container-with-image .ellipsis {
          max-width: var(--result-text-width-kp-adjust-image-adjust-#{$type});
        }
      }
    }

    *,
    :slotted(*) {
      &>* {
        font-size: var(--timeline-font-size-#{$type});
      }

      .markup-container-with-image .ellipsis {
        max-width: var(--result-text-width-image-adjust-#{$type});
      }

      &.accordion-item {
        border-radius: var(--border-radius-timeline-result);
        border: none;

        &>*,
        &:last-of-type>* {
          border-radius: var(--border-radius-timeline-result);
        }
      }
    }

    .icons.clickable,
    .bi-chevron-down,
    .bi-chevron-double-down {
      @include hover-clickable-effect;
    }

    .icons:not(.flag) {
      width: var(--result-container-title-icon-width-#{$type});
      font-size: var(--result-container-title-icon-width-#{$type});
    }

    @if $type =="medium" {
      .icons:not(.flag) {
        margin-right: 0.3rem;
      }

      .flag {
        width: var(--result-container-title-flag-width-medium);
        height: var(--result-container-title-flag-width-medium);
        top: 2px;
        right: -5px;
      }
    }

    .is-scrollable {
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: none;

      &:hover {
        scrollbar-width: thin;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &:hover {
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #aaa;
        }
      }

      max-height: var(--timeline-container-scrolling-height-#{$type});
    }
  }
}


@include zoomViewSizes("regular");
@include zoomViewSizes("medium");
</style>
