<template>
  <result-wrapper class="simple-result"
                  :icon-name="iconName"
                  :element-data="elementData">
    <span class="card-title ms-2" v-html="elementData.displayName"></span>
  </result-wrapper>
</template>

<script>
import ResultWrapper from "@/components/card/ResultWrapper.vue";

export default {
  name: "Generic",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ResultWrapper,
  },
  data() {
    return {
      details: {
        jobbox: { icon: 'job-box' },
        translationbox: { icon: 'translation' },
        locationcontainer: { icon: 'location', displayName: 'Location Box' },
        livesports: { icon: 'live-sports' },
        thingstoknowbox: { icon: 'things-to-know-box', displayName: 'Things To Know' },
        aboutcarousel: { icon: 'about-carousel', displayName: 'About' }
      },
    }
  },
  computed: {
    iconName() {
      return this.details[this.elementData.resulttype]?.icon;
    },
    displayName() {
      return this.elementData.displayName || this.details[this.elementData.resulttype]?.displayName;
    },
  }
};
</script>

<style lang="scss" scoped></style>
